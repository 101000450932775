<template>
    <div></div>
</template>

<script>

import axios from 'axios'
import {mapState} from 'vuex'

export default {
    name : "Sair",
    computed : {
        ...mapState(['uLogar', 'backendUrl', 'usuario'])
    },
    methods : {
        sair() {
            axios.post(`${this.backendUrl}login/sair`, this.usuario).then(() => {
                this.$store.commit('uLogar', null);
                localStorage.removeItem(this.sessaoChave);
                delete axios.defaults.headers.common['token'];
                this.$router.push('/login');
            })
        }
    },
    created() {
        this.sair();
    },
}
</script>

<style scoped>

</style>